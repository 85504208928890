<template>
<div class="p-grid crud-demo">
    <div class="p-col-12">
        <div class="card">
            <Toast/>
            <DataTable ref="dt" :value="items" v-model:selection="selectedItems" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,20,30]"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
                <template #header>
                    <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                        <h5 class="p-m-0">Offres</h5>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                                    <InputText @keyup="search()" v-model="filters" placeholder="Entreprise"/>                               
                        </span>
                    </div>
                </template>
                <Column field="title" header="Titre">
                    <template #body="slotProps">
                        <span class="p-column-title">Titre</span>
                        {{slotProps.data.title}}
                    </template>
                </Column>
                <Column field="remote" header="Remote">
                    <template #body="slotProps">
                        <span class="p-column-title">Remote</span>
                        {{slotProps.data.remote}}
                    </template>
                </Column>
                <Column field="companyName" header="Entreprise" >
                    <template #body="slotProps">
                        <span class="p-column-title">Entreprise</span>
                        {{slotProps.data.companyName}}
                    </template>
                </Column>
                
                <Column field="url" header="Lien"  >
                    <template #body="slotProps">
                        <span class="p-column-title">Lien</span>
                        <a :href=slotProps.data.url target="blank"><Button icon="fas fa-link" class="p-button p-component  p-button-help  "></Button></a>
                    </template>
                </Column>
                <Column field="site" header="Site" >
                    <template #body="slotProps">
                        <span class="p-column-title">Site</span>
                        <div style="white-space:nowrap;"> 
                            {{slotProps.data.site}}
                        </div>
                    </template>
                </Column>
                <Column field="place" header="Lieu" >
                    <template #body="slotProps">
                        <span class="p-column-title">Lieu</span>
                        <div style="white-space:nowrap;">
                                {{slotProps.data.place}}
                        </div>
                    </template>
                </Column>
                <Column field="period" header="Periode" >
                    <template #body="slotProps">
                        <span class="p-column-title">Periode</span>
                        <div style="white-space:nowrap;">
                            {{slotProps.data.period}}
                        </div>
                    </template>
                </Column>
                <Column field="rate" header="Tarif" >
                    <template #body="slotProps">
                        <span class="p-column-title">Tarif</span>
                        {{slotProps.data.rate}}
                    </template>
                </Column>
                <Column field="start_at" header="Début" >
                    <template #body="slotProps">
                        <span class="p-column-title">Début</span>
                        {{slotProps.data.startAt}}
                    </template>
                </Column>
                    <Column>
                    <template #body="slotProps">
                        <Button icon="fas fa-eye" class="p-button  p-button-raised p-component p-button-warning p-mr-2" @click="openNew(slotProps.data.description)" />                       
                    </template>
                </Column>
            </DataTable>
                <Dialog v-model:visible="itemDescription" :style="{width: ''}" header="Description" :modal="true" class="p-fluid">
                <ScrollPanel style="width: 1000px; height: 500px">
                    <div class="p-field formatDesc ">
                        <p>{{description}}</p>
                    </div>
                    <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
                </ScrollPanel>
            </Dialog>
        </div>
    </div>
</div>

</template>

<script>

import useVuelidate from '@vuelidate/core'


export default {
data() {
    return {
        description: "",
        items: null,
        itemDescription: false,
        deleteItemDialog: false,
        deleteItemsDialog: false,
        item: {},
        currentPage:1,
        selectedItems: null,
        filters: null,
        isSubmitted: false
    }
},
setup: () => ({ v$: useVuelidate() }),
mounted() {
    this.reloadData();
},
methods: {
    reloadData() {
        
        this.$resources.offers.list().then(response => this.items = response.data);   
    },

    search() {
        this.$resources.offers.list(this.currentPage, { companyName: this.filters }).then(response => this.items = response.data);
   
    },
    openNew(desc) {
        this.itemDescription = true;
        this.description = desc;
    },
}
}
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.formatDesc{
    white-space: pre;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-instock {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-outofstock {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-lowstock {
        background: #FEEDAF;
        color: #8A5340;
    }
}

::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
        margin-bottom: .25rem;
    }
}
</style>
